.all {
    text-align: center;
    /* height: 350px; */
    height: 1000px;
    background-color: white;
}

.title {
    font-size: 25px;
    color: blue;
    font-weight: bold;
    margin-top: 10px;
}

.text4 {
    position: relative;
    bottom: 2px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.title1 {
    font-size: 25px;
    color: green;
    font-weight: bold;
    margin-top: 10px;
}

.title2 {
    font-size: 25px;
    color: rgb(168, 0, 0);
    font-weight: bold;
    margin-top: 10px;
}
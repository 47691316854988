.body2 {
    text-align: center;
    background-color: rgb(214, 166, 127);
    height: 280px;
}

.text1 {
    font-size: 30px;
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    color: green;
    font-weight: bold;
    position: relative;
    top: 30px;
}

.text2 {
    font-size: 20px;
    position: relative;
    top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
}

.text3 {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 30px;
}

.chestgrp1 {
    float: left;
    position: relative;
    top: 20px;
}

.chestgrp2 {
    float: right;
    position: relative;
    top: 20px;
}

.chestgrp3 {
    float: left;
    position: relative;
    top: 20px;
}

.chestgrp4 {
    float: right;
    position: relative;
    top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .chestgrp1 {
        float: left;
        position: relative;
        top: 20px;
        height: 100px;
        width: 150px;
    }
    .chestgrp2 {
        float: right;
        position: relative;
        top: 10px;
        height: 100px;
        width: 150px;
    }
    .text1 {
        position: relative;
        font-size: 10px;
        top: 170px;
        font-weight: bold;
        width: 150px;
        height: 20px;
    }
    .chestgrp3 {
        float: right;
        position: relative;
        top: 20px;
        height: 100px;
        width: 150px;
    }
    .chestgrp4 {
        height: 100px;
        width: 150px;
        position: relative;
        top: 25px;
        float: left;
    }
    .text2 {
        position: relative;
        font-size: 10px;
        top: 10px;
        font-weight: bold;
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .text3 {
        font-size: 10px;
        width: 360px;
        top: 15px;
        height: 2px;
    }
}
.Footer {
    height: 140px;
    background-color: rgb(204, 204, 204);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.footertxt, .links {
    text-align: center;
    position: relative;
    bottom: 47px;
}

.DT {
    position: relative;
    left: 238px;
    bottom: 24px;
}

.rubyfooter {
    margin: 0px 900px 0px 0px;
    position: relative;
    top: 26px;
}

.links11 {
    padding-left: 20px;
    color: black;
    position: relative;
    bottom: 120px;
    left: 70px;
}

.links12 {
    padding-left: 20px;
    color: black;
    position: relative;
    bottom: 120px;
    left: 74px;
}

.fox {
    position: relative;
    left: 300px;
    bottom: 105px;
}

.egg {
    position: relative;
    left: 300px;
    bottom: 130px;
    height: 75px;
    width: 80px;
}

.logos {
    position: relative;
    left: 236px;
    bottom: 105px;
    padding-right: 5px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .footertxt {
        position: relative;
        bottom: 0px;
        left: 10px;
        font-size: 10px;
    }
    .rubyfooter {
        margin: 0px 900px 0px 0px;
        position: relative;
        top: 40px;
        height: 100px;
        width: 100px;
    }
    .links12 {
        position: relative;
        height: 100px;
        width: 100px;
        font-size: 10px;
        bottom: 40px;
        left: 50px;
    }
    .links11 {
        position: relative;
        bottom: 40px;
        height: 100px;
        width: 100px;
        font-size: 10px;
        left: 60px;
    }
    .DT {
        position: relative;
        left: -85px;
        bottom: -47px;
    }
    .fox {
        position: relative;
        left: 50px;
        top: -60px;
        height: 50px;
        width: 50px;
    }
    .egg {
        position: relative;
        left: 45px;
        top: -60px;
        height: 40px;
        width: 40px;
    }
    .Footer {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
.body1 {
    text-align: center;
    background-color: rgb(255, 255, 255);
    height: 500px;
}

.text {
    font-size: 30px;
    font-weight: bold;
    position: relative;
    top: 70px;
}

.button {
    background-color: rgb(80, 47, 5);
    color: white;
    border: 2px solid rgb(80, 47, 5);
    font-size: 30px;
    padding: 10px 60px 10px 60px;
    border-radius: 15px;
    position: relative;
    top: 85px;
}

.button:hover {
    background-color: rgb(36, 18, 1);
    border: 2px solid rgb(36, 18, 1);
    cursor: pointer;
    transition: 0.5s;
}

.textbox {
    border: none;
    border: 3px solid brown;
    position: relative;
    top: 80px;
    font-size: 20px;
    border-radius: 10px;
    height: 40px;
    width: 175px;
    text-align: center;
}

.cost {
    position: relative;
    top: 80px;
}

.logo {
    height: 90px;
    width: 200px;
    position: relative;
    top: 50px;
}

.button2 {
    background-color: rgb(189, 131, 57);
    color: rgb(0, 0, 0);
    border: 2px solid rgb(189, 131, 57);
    font-size: 18px;
    padding: 10px 12px 10px 12px;
    border-radius: 30px;
    text-align: center;
    position: relative;
    top: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.button2:hover {
    background-color: rgb(155, 107, 46);
    border: 2px solid rgb(155, 107, 46);
    cursor: pointer;
    transition: 0.5s;
}
* {
    margin: 0;
    padding: 0;
}

.headerlogo {
    height: 70px;
    width: 150px;
}

.header {
    background-color: rgba(73, 27, 0, 0.6) !important;
    text-align: center;
}

.nav-item .nav-link {
    color: white !important;
    padding: 0px 10px 0px 10px !important;
    font-size: 13px !important;
    font-weight: bold !important;
}

.link {
    color: white;
    text-decoration: none;
    padding: 0px 10px 0px 10px;
    position: relative;
    bottom: 25px;
    font-size: 15px;
    font-weight: bold;
}

.body1 {
    background-color: rgb(255, 255, 255);
    text-align: center;
    height: 450px;
}

.button1 {
    color: white;
    background-color: orange;
    border: none;
    font-size: 18px;
    border-radius: 10px;
    position: relative;
    bottom: 25px;
    padding: 7px 35px 7px 35px;
}

.button1:hover {
    background-color: rgb(204, 133, 1);
    transition: 0.3s;
    cursor: pointer;
}

.more {
    cursor: default;
    color: white;
    text-decoration: none;
    padding: 0px 10px 0px 10px;
    position: relative;
    bottom: 25px;
    font-size: 15px;
    font-weight: bold;
}

@media (min-width: 320px) and (max-width: 480px) {
    .headerlogo {
        position: relative;
        left: -17px;
    }
    .link {
        font-size: 10px;
        position: relative;
        top: -7px;
    }
    .more {
        font-size: 10px;
        position: relative;
        top: -7px;
    }
}